import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import Seo from '../components/seo'

import { MdPlace, MdDateRange, MdPerson, MdAccountBalance, MdLink, MdTurnedIn } from 'react-icons/md'

class Section extends React.Component {
  render () {
    return (
      <div>
        <h2 className='font-sans text-3xl'>{this.props.title}</h2>
        <div>{this.props.children}</div>
      </div>
    )
  }
}

class ExperienceElement extends React.Component {
  render () {
    return <div className='mt-6'>
      <h4 className='font-mono'>{this.props.title}</h4>
      <div style={{ width: '3em', height: 5 }} className='bg-red-500 mb-3'></div>
      <p className='text-xs'><MdPerson style={{ display: 'inline' }}/> {this.props.role}</p>
      <p className='text-xs'><MdDateRange style={{ display: 'inline' }}/> {this.props.period}</p>
      <p className='text-xs mb-3'><MdPlace style={{ display: 'inline' }}/> {this.props.where}</p>
      <p className='font-mono'>{this.props.description}</p>
    </div>
    
  }
}

class EducationElement extends React.Component {
  render() {
    return <div className='mt-6'>
      <h4 className='font-mono'>{this.props.title}{ this.props.qualification && ` - ${this.props.qualification}`}</h4>
      <div style={{ width: '3em', height: 5 }} className='bg-red-500 mb-3'></div>
      <p className='text-xs'><MdDateRange style={{ display: 'inline' }}/> {this.props.period}</p>
      <p className='text-xs'><MdAccountBalance style={{ display: 'inline' }}/> {this.props.school}</p>
      <p className='text-xs mb-3'><MdPlace style={{ display: 'inline' }}/> {this.props.where}</p>
    </div>
    
  }
}

class CertificationElement extends React.Component {
  render() {
    return <div className='mt-6'>
      <h4 className='font-mono'>{this.props.title}{ this.props.autority && ` - ${this.props.authority}`}</h4>
      <div style={{ width: '3em', height: 5 }} className='bg-red-500 mb-3'></div>
      <p className='text-xs'><MdDateRange style={{ display: 'inline' }}/> {this.props.duration}</p>
      <p className='text-xs'><MdTurnedIn style={{ display: 'inline' }}/> {this.props.id}</p>
      <p className='text-xs mb-3'><MdLink style={{ display: 'inline' }}/> <a href={this.props.link}>{this.props.link}</a></p>
    </div>
  }
}

class BlogIndex extends React.Component {
  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    const experiences = data.allExperiencesJson.edges;
    const education = data.allEducationsJson.edges;
    const certification = data.allCertificationsJson.edges;

    console.log(data.siteTitle)
    return (
      <Layout location={this.props.location} title={`${siteTitle}`}>
        <Seo
          title='Personal Website of Davide Ereno'
          keywords={['Davide Ereno', 'engineer', 'cv', 'problem solving', 'resumee']}
        />
        <Bio />
        <div className='border border-red-500 border-solid mt-8 mb-8' />
        <Section title='Experiences'>
          {
            experiences.map((ex, i) => (
              <ExperienceElement
                key={`ex_${i}`}
                title={ex.node.title}
                period={ex.node.period}
                role={ex.node.role}
                description={ex.node.description}
                where={ex.node.where}
              />
            ))
          }
        </Section>
        <div className='border border-red-500 border-solid mt-8 mb-8' />
        <Section title='Education'>
          {
            education.map((ex, i) => (
              <EducationElement
                key={`ex_${i}`}
                title={ex.node.title}
                period={ex.node.period}
                qualification={ex.node.qualification}
                school={ex.node.school}
                where={ex.node.where}
              />
            ))
          }
        </Section>
        <div className='border border-red-500 border-solid mt-8 mb-8' />
        <Section title='Certification'>
          {
            certification.filter(el => el.node.expiration === null || (new Date(el.node.expiration) > (new Date())))
              .map((el, i) => (
                <CertificationElement
                  key={`cert_${i}`} 
                  title={el.node.title}
                  authority={el.node.authority}
                  duration={el.node.duration}
                  id={el.node.id}
                  link={el.node.link}
                />
              ))
          }
        </Section>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query MyQuery {
    site {
      siteMetadata {
        title
      }
    }
    allExperiencesJson {
      edges {
        node {
          description
          period
          role
          title
          where
        }
      }
    }
    allEducationsJson {
      edges {
        node {
          period
          qualification
          school
          title
          where
        }
      }
    }
    allCertificationsJson {
     edges {
      node {
        title
        authority
        duration
        id
        link
        expiration 
      }
     }
    }
  }
`
